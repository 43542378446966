<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>公司资质材料</span>
        </div>
        <div class="cert_stat">
            <div class="flex">
                <div style="line-height:27px;" class="flex-1">
                    <span>当前状态：</span><span>待审核</span>
                </div>
                <div>
                    <el-button style="margin-right:20px;" size="mini" type="primary">同意</el-button>
                    <el-button size="mini">驳回</el-button>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="header">服务能力</div>
            <div class="list">
                <div class="flex">
                    <div>加工属性</div>
                    <div class="select_list flex" v-for="(item,idx) in selectList" :key="idx">
                        <div>{{item.name}}</div>
                    </div>
                </div>
                <div class="flex">
                    <div>主要木材加工种类</div>
                    <div class="select_list flex" v-for="(item,idx) in selectList" :key="idx">
                        <div>{{item.name}}</div>
                    </div>
                </div>
                <div class="flex">
                    <div>主打产品</div>
                    <div class="select_list flex" v-for="(item,idx) in selectList" :key="idx">
                        <div>{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="header">工厂现状</div>
            <div class="list list_active">
                <div class="flex">
                    <div>总人数</div>
                    <div>{{ruleForm.name}}</div>
                </div>
                <div class="flex">
                    <div>成立日期</div>
                    <div>{{ruleForm.time}}</div>
                </div>
                <div class="flex">
                    <div>工厂图片</div>
                    <div style="padding-top:10px;">
                        <div class="credit-img" v-for="(item,idx) in ruleForm.design_photos" :key="idx">
                            <img :src="imgUrl+item" style="width: 100%;height: 100%;">
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div>工厂视频</div>
                    <div style="padding-top:10px;">
                        <div class="credit-img" v-for="(item,idx) in ruleForm.design_photos" :key="idx">
                            <img :src="imgUrl+item" style="width: 100%;height: 100%;">
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div>工厂简介</div>
                    <p>{{ruleForm.name}}</p>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="header">相关资料</div>
            <div class="list list_active">
                <div class="flex">
                    <div>获奖荣誉证书</div>
                    <div style="padding-top:10px;">
                        <div class="credit-img" v-for="(item,idx) in ruleForm.design_photos" :key="idx">
                            <img :src="imgUrl+item" style="width: 100%;height: 100%;">
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div>企业质量体系证</div>
                    <div style="padding-top:10px;">
                        <div class="credit-img" v-for="(item,idx) in ruleForm.design_photos" :key="idx">
                            <img :src="imgUrl+item" style="width: 100%;height: 100%;">
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div>官网链接</div>
                    <div>{{ruleForm.link}}</div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="header">工艺能力</div>
            <div class="list list_active">
                <div class="flex">
                    <div style="width:105px;text-align:right;">工艺能力：</div>
                    <div style="width:calc(100% - 120px);border:1px solid #EBEEF5;">
                        <el-table :data="ruleForm.ability" style="width: 100%">
                            <el-table-column prop="name" label="工艺名称">
                            </el-table-column>
                            <el-table-column prop="zhibiao" label="衡量指标">
                            </el-table-column>
                            <el-table-column prop="privs" label="对应值">
                                <template slot-scope="scope">
                                    <div>{{scope.row.bfb}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="主要工艺">
                                <template slot-scope="scope">
                                    <div>{{scope.row.yes}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import localForage from 'localforage';
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                rules: {
                    personNum: [
                        { required: true, message: '请输入申请数量', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输入申请说明', trigger: 'blur' }
                    ]
                },
                ruleForm:{
                    title:"xxxxx家具品牌公司",
                    person:"若风",
                    personNum:"",
                    stat:0,
                    time:"",
                    desc:"",
                    design_photos:["111",],
                    photos:[],
                    link:"http://demo.emergen.cn:8808/",
                    ability:[],
                    radio:"",
                },
                selectList:[
                    {id:0,name:"充值点数",},
                    {id:1,name:"需求发布次数",},
                    {id:2,name:"广播消息次数",},
                    {id:3,name:"查看次数",},
                ],
                selectIndex:0,
                selectValue:[],
                showImgs:false,
            }
        },
        created() {
            this.showImgs = true;
            var obj = {
                name:"喷漆",
                zhibiao:"执色、黏合度",
                bfb:"90%",
                yes:"是"
            }
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.back();
                this.$message.success("保存成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .cert_stat {
        padding: 15px 30px;
        background: #fff;
        margin: 20px;
    }
    .cert_stat span {
        font-size: 15px;
        color: #333;
    }
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 30px;
        margin:10px 0;
    }
    .list>div>div:first-child {
        width: 115px;
        text-align: right;
        color: #000;
        margin-right: 15px;
    }
    /* .list.list_active>div>div:first-child {
        width: 85px;
    } */
    .credit-img{
        width:145px;
        height:145px;
        border:1px dashed rgba(204,204,204,1);
        overflow: hidden;
        display: inline-block;
    }
    .select_list>div {
        padding:0 15px;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        border: 1px solid rgb(64, 158, 255);
        color: rgb(64, 158, 255);
        border-radius: 4px;
        margin-top: 3px;
        margin-right:15px;
    }
</style>